import React, { useRef, useState } from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import CustomDropdown from "../../../../components/CustomDropdown";
import TextInput from "../../../../components/TextInput";
import LoaderScreen from "../../../../components/LoaderScreen";
import SimpleReactValidator from "simple-react-validator";
import Select from 'react-select';
import { addNotification } from "../../../../components/Notification";

const NewPost = ({
  statusText,
  setStatusText,
  statusValue,
  setStatusValue,
  statusValues,
  addBannerHandler,
  getStatusValues,
  loading,
  regionValues,
  bannerTypes,
  bannerText,
  setBannerText,
  bannerValue,
  setBannerValue,
  getBannerTypeStatus,
  selectedOptions,
  handleChange
}) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [bannerName, setBannerName] = useState("");
  const [file, setFile] = useState("");

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#ffffff',
      zIndex: 9999,
      border: '1px solid #ccc',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    }),
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.post}>
        <div className={styles.details}>
          <div className={cn("title-purple", styles.title)}>Add Banner</div>
          <div className={styles.description}>
            <div className={styles.customField}>
              {/* <Dropdown
                label="Region"
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                options={regionValues}
                small
              /> */}
              <Select
                isMulti
                options={regionValues}
                value={selectedOptions}
                onChange={handleChange}
                placeholder="Select options"
                styles={customStyles}
              />
            </div>
            <div className={styles.customField}>
              <CustomDropdown
                className={styles.dropdown}
                label="Banner Type"
                classDropdownHead={styles.dropdownHead}
                value={bannerValue}
                setValue={setBannerValue}
                text={bannerText}
                setText={setBannerText}
                options={bannerTypes}
                handleClick={getBannerTypeStatus}
                small
              />
            </div>
            <div className={styles.customField}>
              <CustomDropdown
                className={styles.dropdown}
                label={"Status"}
                classDropdownHead={styles.dropdownHead}
                value={statusValue}
                setValue={setStatusValue}
                text={statusText}
                setText={setStatusText}
                options={statusValues}
                handleClick={getStatusValues}
                small
              />
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Banner Name"
                name="bannerName"
                type="text"
                onChange={(e) => setBannerName(e.target.value)}
                placeholder="Please enter a value"
                required
                onBlur={() => simpleValidator.current.showMessageFor("bannerName")}
              />
              <span className={styles.danger}>
                {simpleValidator.current.message("bannerName", bannerName, "required")}
              </span>
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Banner Image"
                name="bannerImage"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                required
                onBlur={() => simpleValidator.current.showMessageFor("bannerImage")}
              />
              <span className={styles.danger}>
                {simpleValidator.current.message("bannerImage", file, "required")}
              </span>
            </div>
          </div>
          <div className={styles.control}>
            <button className={cn("button", styles.button)} onClick={(e) => {
              e.preventDefault();
              if (simpleValidator.current.allValid()) {
                if (selectedOptions.length !== 0) {
                  addBannerHandler(statusValue, bannerName, file);
                }
                else {
                  addNotification({
                    title: "Error",
                    message: "Please select region.",
                    type: "danger",
                  });
                }
              }
              else {
                simpleValidator.current.showMessages();
                forceUpdate(1);
              }
            }
            }>
              <span>Continue</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPost;
