import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const statusValues = [
  { "id": 1, "name": "Enable" },
  { "id": 2, "name": "Disable" },
];

// const regionValues = [
//   process.env.REACT_APP_EUROPE_REGION_TEXT.toUpperCase(),
//   process.env.REACT_APP_DEFAULT_REGION_TEXT.toUpperCase(),
//   process.env.REACT_APP_SURINAME_REGION.toUpperCase(),
//   process.env.REACT_APP_GLOBAL_REGION_TEXT.toUpperCase(),
// ];

const regionValues = [
  { value: process.env.REACT_APP_EUROPE_REGION_TEXT.toUpperCase(), label: process.env.REACT_APP_EUROPE_REGION_TEXT.toUpperCase() },
  { value: process.env.REACT_APP_DEFAULT_REGION_TEXT.toUpperCase(), label: process.env.REACT_APP_DEFAULT_REGION_TEXT.toUpperCase() },
  { value: process.env.REACT_APP_SURINAME_REGION.toUpperCase(), label: process.env.REACT_APP_SURINAME_REGION.toUpperCase() },
  { value: process.env.REACT_APP_GLOBAL_REGION_TEXT.toUpperCase(), label: process.env.REACT_APP_GLOBAL_REGION_TEXT.toUpperCase() }
];

const bannerTypes = [
  { "id": parseInt(process.env.REACT_APP_GLOBAL_BANNER), "name": "Global" },
  { "id": parseInt(process.env.REACT_APP_HOME_BANNER), "name": "Home" },
  { "id": parseInt(process.env.REACT_APP_BITDENEX_PAY_BANNER), "name": "Bitdenex Pay" },
  { "id": parseInt(process.env.REACT_APP_NFT_BANNER), "name": "Nft" },
];

const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [statusValue, setStatusValue] = useState(statusValues[0].id);
  const [statusText, setStatusText] = useState(statusValues[0].name);
  const [bannerData, setBannerData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [bannerText, setBannerText] = useState(bannerTypes[0]?.name);
  const [bannerValue, setBannerValue] = useState(bannerTypes[0]?.id);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    getBannerListHandler();
  }, [statusValue]);

  const getBannerListHandler = async () => {
    setLoading(true);
    let data = {
      status: statusValue,
      signature: localStorage.getItem("signature"),
    };
    try {
      const getBannerListPayload = await requestHandler("listBannersByStatus", "post", data, "jwt_token");
      setBannerData(getBannerListPayload?.data?.message?.banners);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const addBannerHandler = async (status, name, image) => {
    setLoading(true);
    const selectedValues = selectedOptions?.map(option => option?.value?.toLowerCase());
    let data = {
      banner_name: name,
      status: status,
      image: image,
      signature: localStorage.getItem("signature"),
      region: selectedValues,
      type: bannerValue,
    };
    try {
      const addBannerPayload = await requestHandler("uploadBanner", "post", data, "jwt_token", "file");
      setLoading(false);
      if (addBannerPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: addBannerPayload?.data?.message[0]?.msg,
          type: "success",
        });
        setVisibleModal(false);
        getBannerListHandler();
        setSelectedOptions([]);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong, please try again.",
        type: "danger",
      });
    };
  };

  const getStatusValues = (x) => {
    setStatusText(x.name);
    setStatusValue(x.id);
  };

  const getBannerTypeStatus = (x) => {
    setBannerText(x?.name);
    setBannerValue(x?.id);
  };

  const updateStatusHandler = async (id, status) => {
    setLoading(true);
    let data = {
      bannerId: id,
      newStatus: status,
      signature: localStorage.getItem("signature"),
    };
    try {
      const updateStatusPayload = await requestHandler("updateBannerStatus", "post", data, "jwt_token");
      setLoading(false);
      if (updateStatusPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: updateStatusPayload?.data?.message[0]?.msg,
          type: "success",
        });
        getBannerListHandler();
      }
    }
    catch (e) {
      addNotification({
        title: "Error",
        message: "Something went wrong, please try again later.",
        type: "danger",
      });
      setLoading(false);
    };
  };

  const handleChange = (options) => {
    setSelectedOptions(options);
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          statusText={statusText}
          setStatusText={setStatusText}
          statusValue={statusValue}
          setStatusValue={setStatusValue}
          statusValues={statusValues}
          getStatusValues={getStatusValues}
          bannerData={bannerData}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          addBannerHandler={addBannerHandler}
          updateStatusHandler={updateStatusHandler}
          regionValues={regionValues}
          bannerTypes={bannerTypes}
          bannerText={bannerText}
          setBannerText={setBannerText}
          bannerValue={bannerValue}
          setBannerValue={setBannerValue}
          getBannerTypeStatus={getBannerTypeStatus}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default Promote;
